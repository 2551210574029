<template>
  <div style="padding: 9vh 0%">
    <five-section></five-section>
  </div>
</template>

<script>
import fiveSection from "../components/fiveSection.vue";

export default {
  components: { fiveSection },
  name: "priceList",
};
</script>
