<template>
  <section class="tan">
    <h2>Opalanie natryskowe</h2>
    <p>
      Opalanie natryskowe jest jedną z bezpieczniejszych metod opalania. Główną zaletą jest uzyskanie natychmiastowego efektu, takiego jakiego klientka sobie wymarzy. Opalanie natryskowe jest całkowicie bezpieczne i mogą z niego korzystać zarówno mężczyźni, jak i kobiety. Taka forma opalania nadaje się również dla kobiet w ciąży, ponieważ nie wywołuje chorób skóry.<br><br>
Zabieg opalania natryskowego polega na nałożeniu na skórę składnika aktywnego – DHA (bezbarwny cukier), za pomocą specjalistycznej natryskowej maszyny. Innymi słowy ta metoda polega na spryskaniu ciała odpowiednim barwnikiem. Preparat brązujący ma właściwości nawilżające oraz odżywia skórę. W większości przypadków nie powoduje reakcji alergicznych natomiast istnieje możliwość zrobienia testu alergicznego przed zaplanowaną wizytą. Kiedy produkt zostanie nałożony na skórę, oddziałuje na aminokwasy i w ten sposób delikatnie pobudza produkcję melaniny. Efekt opalania jest natychmiastowy, natomiast kolor może się rozwijać do 24h.<br><br>
Opalanie natryskowe jest wykonywane w specjalnej kabinie. Wchodzi się do niej w podkładkach pod stopy oraz w jednorazowej bieliźnie. Istnieje możliwość opalania się w swojej bieliźnie. By chronić włosy, na głowę zakładany jest czepek.<br><br>
Wizyta trwa około 30 minut, a sama aplikacja preparatu brązującego trwa z reguły 15 minut.<br><br>
Opalenizna utrzymuje się od 7 do nawet 14 dni. Aby utrzymywała się dłużej należy pamiętać o odpowiednim nawilżeniu ciała. Po tym czasie opalenizna blednie i wymywa się. W przypadku powstawania jakichkolwiek przebarwień na skórze zalecane jest zrobienie peelingu w celu wyrównania opalenizny.<br><br>
Przeciwskazaniem do tego typu zabiegu kosmetycznego są:<ul>
<li>świeże rany pooperacyjne</li>
<li>wszelkie choroby skórne (łuszczyca zaostrzona)</li>
<li>świeże tatuaże</li>
<li>popalenia słoneczne</li>
<li>zabiegi inwazyjne (wykonaj je co najmniej 10 dni przed opalaniem)</li>
<li>samoopalacze (można je stosować co najmniej 7 dni przed planowaną wizytą).</li></ul>
O to, czy możesz skorzystać z zabiegu opalania natryskowego najlepiej zapytać kosmetyczkę podczas umawiania terminu.

    </p>
  </section>
</template>

<script>
export default {
  name: "tan",
};
</script>

<style>
.tan {
  margin-top: 80px;
  padding: 10vh 0%;
  box-sizing: border-box;
  background: #cf9d75;
  width: 100%;
  text-align: center;
}

.tan h2 {
  font-size: 80px;
  color: #ffffff;
  position: relative;
  top: 5px;
  text-transform: uppercase;
  margin: 0 0 5vh 0;
  padding: 0;
}

.tan p {
  padding: 0% 10vw;
  box-sizing: border-box;
  text-align: justify;
  color: #fff;
  font-weight: 500;
}

.tan p a {
  color: #fff0e3;
  text-decoration: none;
}

.tan p a:hover {
  text-decoration: underline;
}

ul li,
ul {
  margin-left: 10px;
  padding-left: 0;
}

@media only screen and (max-width: 870px) {
  .tan p {
    text-align: left;
  }
}
</style>